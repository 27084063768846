<template>
  <div>
    <div class="tab-content" id="calendarTabContent">
      <template v-for="(i, index) in calendar">
        <div
          class="tab-pane fade"
          :class="{ 'show active': index === 0 }"
          :id="`month${index}`"
          role="tabpanel"
          :aria-labelledby="`month${index}-tab`"
        >
          <div class="ratio ratio-4x3">
            <iframe
              :src="`https://freecalend.com/open/mem82434_date${i}`"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";

export default {
  name: "IframeCalendar",
  data() {
    return {
      month: moment(),
      calendar: [
        moment().clone().add(0, "M").format("YYYYMM"),
        moment().clone().add(1, "M").format("YYYYMM"),
        moment().clone().add(2, "M").format("YYYYMM"),
        moment().clone().add(3, "M").format("YYYYMM"),
        moment().clone().add(4, "M").format("YYYYMM"),
        moment().clone().add(5, "M").format("YYYYMM"),
      ],
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYYMM");
    },
    momentJP: function (date) {
      return moment(date).format("M月");
    },
  },
};
</script>
