import "../scss/app.scss";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
import * as bootstrap from "bootstrap";
const carousels = require("./carousels");
import Calendar from "./components/Calendar.vue";
import IframeCalendar from "./components/IframeCalendar.vue";

const app = new Vue({
  el: "#app",
  components: {
    Calendar,
    IframeCalendar,
  },
  data: {
    isToggleMenu: false,
    photos: [],
    api: "https://graph.facebook.com/v3.0/17841433853245078?fields=name%2Cmedia.limit(10)%7Bcaption%2Clike_count%2Cmedia_url%2Cmedia_type%2Cthumbnail_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=EAAFGM5vZAF5IBAHndvCE74IcIVokbiejalj3c2KPB3h9n2vDKJ5CDgB9eX71oIEJszMDg694Y66Tt6tKnmyNoxNNUffWWoYod5XV9makzC2FmR3Ki5PUA7EIN5ZCfCqG4VyyoVyGtX3DF6t5CHutWZCcYlKvqw1mDoJW0GwvYUngBtdCp3Lq8TZAFxvNGG4ZD",
  },
  mounted() {
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
    this.getPhotos();
    var toastElList = [].slice.call(document.querySelectorAll(".toast"));
    var toastList = toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl, {
        // オプション
        delay: 10000,
      });
    });
    toastList[0].show();
  },
  methods: {
    getPhotos() {
      this.axios
        .get(this.api)
        .then((response) => {
          this.photos = response.data.media.data;
          // console.log(this.photos);
        })
        .catch((e) => {
          this.photos = "エラーが発生しました。";
        });
    },
  },
});
